'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:DispatchUnitStatusesManager

 # @description

###
angular
  .module 'mundoAdmin.dispatch'
  .factory 'DispatchUnitStatusesManager', [
    'MundoDefaultManager'
    'TenantManager'
    (
      MundoDefaultManager
      TenantManager
    )->
      DispatchUnitStatusesManagerBase = new MundoDefaultManager()

      DispatchUnitStatusesManagerBase.setUrl('lpa/dispatch/units/statuses')
      DispatchUnitStatusesManagerBase.setAutoTenant()
      DispatchUnitStatusesManagerBase.setNewObject(['label', 'code', 'available', 'color', 'tenant'])
      DispatchUnitStatusesManagerBase.setUpdateObject(['label', 'code', 'available', 'color'])
      DispatchUnitStatusesManagerBase.setSearchFields(['label', 'code'])

      DispatchUnitStatusesManagerBase.new = (data) ->
        if (data.color)
          data.color = data.color.replace '#', ''

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()),{}, autoTenant : @conf.autoTenant )

      DispatchUnitStatusesManagerBase.update = (id, data) ->
        if (data.color)
          data.color = data.color.replace '#', ''

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      DispatchUnitStatusesManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'code'
          name: 'code'
          type: 'input'
          templateOptions:
            label: 'Code'
            placeholder: 'code'
            required: true
        ,
          key: 'available'
          name: 'available'
          type: 'checkbox'
          templateOptions:
            label: 'Beschikbaar'
            required: false
        ,
          key: 'color'
          name: 'color'
          type: 'color'
          templateOptions:
            label: 'Kleur'
            required: true
        ]

      DispatchUnitStatusesManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'code'
          name: 'code'
          defaultValue: data.code
          type: 'input'
          templateOptions:
            label: 'Code'
            placeholder: 'code'
            required: true
        ,
          key: 'available'
          name: 'available'
          type: 'checkbox'
          defaultValue: data.available
          templateOptions:
            label: 'Beschikbaar'
            required: false
        ,
          key: 'color'
          name: 'color'
          type: 'color'
          defaultValue: '#' + data.color
          templateOptions:
            label: 'Kleur'
            placeholder: 'Kleur'
            required: true
        ]

      DispatchUnitStatusesManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'code'
          title: 'datatable.label.code'
          sort: 'code'
        ,
          key: 'available'
          title: 'datatable.label.available'
          sort: 'available'
          type: 'boolean'
        ,
          key: 'color'
          title: 'datatable.label.color'
          sort: 'color'
          type: 'color'
        ]

      DispatchUnitStatusesManagerBase.editPermission =
        'manage all MundoMosaLpaDispatchingBundle:DispatchUnitStatus entities'

      DispatchUnitStatusesManagerBase
  ]
